<template>
    <div class="card p-2" style="min-height: 200px">
        <div class="table-responsive">
            <table class="table">
                <thead>
                    <tr>
                        <th>Date</th>
                        <th>Voucher No</th>
                        <th>Description</th>
                        <th>Amount</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, i) in journals" :key="i">
                        <td>
                            <span class="fw-bold">{{ item.date }}</span>
                        </td>
                        <td>{{ item.voucher_no }}</td>
                        <td> {{item.note}} </td>
                        <td> {{ commaFormat(item.total_amount) }} </td>
                        <td>
                            <button class="btn btn-primary btn-sm"
                                    style="cursor: pointer"
                                    @click="goToEdit(item.id)"
                            >
                                <i class="fas fa-eye"></i>
                            </button>
                            <button class="btn btn-secondary btn-sm"
                                    @click="goToPrint(item.id)"
                                    style="cursor: pointer; margin-left: 10px"
                            >
                                <i class="fas fa-print"></i>
                            </button>
                        </td>
                    </tr>
                    
                </tbody>
            </table>
            <p v-if="!journals.length" class="text-center mt-5">No data</p>
        </div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import figureFormatter from '@/services/utils/figureFormatter'
import handlePayment from "@/services/modules/payment";
import handleBarcode from "@/services/modules/barcode";
import ngoPdfPrinter from "@/services/utils/ngoPdfPrinter";
import handleCompany from "@/services/modules/company";
import handleJournal from "@/services/modules/journal";

export default {
    name: 'JournalTable',
    computed: {
        ...mapGetters ({
            journals: 'journal/getJournals'
        })
    },
    data: () => ({
        barcodeQuery: '',
        barcode: '',
        qrcode: '',
        companyInfo: {}
    }),
    setup() {
        const {commaFormat} = figureFormatter ()
        const {fetchJournal} = handleJournal();
        const { fetchBarcode } = handleBarcode();
        const { exportToPDF, formatDate } = ngoPdfPrinter();
        const { fetchCompanyInfo } = handleCompany();

        return {
            formatDate,
            exportToPDF,
            commaFormat,
            fetchBarcode,
            fetchCompanyInfo,
            fetchJournal
        }
    },
    methods: {
        goToEdit(id) {
            this.$router.push({name: 'EditJournal', 
                params: {
                    companyId: this.$route.params.companyId,
                    moduleId: this.$route.params.moduleId,
                    menuId: this.$route.params.menuId,
                    pageId: this.$route.params.pageId,
                    journalId: id
                },
                query: {
                    start: this.$route.query.start,
                    end:  this.$route.query.end
                }
            
            })
        },
        goToPrint(id) {
            this.fetchJournal(id).then( (res) => {
                if(res){
                    this.payment = res.data;
                    let qrcodeData = JSON.stringify({
                        ref_no: this.payment.voucher_no,
                        ref_date: this.payment.date,
                        created_by: this.payment.user.name,
                        created_date_time: this.formatDate(new Date())
                    })
                    this.barcodeQuery = `?code=${this.payment.voucher_no}&qrcode=${qrcodeData}`;
                }}).then (() => {
                this.fetchBarcode(this.barcodeQuery).then( (res) => {
                    this.barcode = res.barcode;
                    this.qrcode = res.qrcode;
                }).then( () => {
                    this.exportToPDF(this.companyInfo, this.payment, this.barcode, this.qrcode, 'journal');
                })}).catch((err) => {
                console.log('Something went wrong', err);
            })
        }
    },
    mounted() {
        this.fetchCompanyInfo(this.$route.params.companyId).then( (res) => {
            this.companyInfo = res.data;
        }).catch( (err) => {
            console.log(err);
        })
    }
}
</script>
