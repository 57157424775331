<template>
  <div v-if="isOpen" class="modal fade show" aria-labelledby="addNewCardTitle"
       style="display: block; background: #1c1d1c33">
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content">
        <div class="row">
          <div class="col-12">
            <div class="d-flex justify-content-between px-1 my-1 align-item-center">
              <h1 class="mb-0">{{ isEdit ? 'Update' : 'Add' }} {{ title }}</h1>
              <button
                  @click="toggleModal"
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
              ></button>
            </div>
          </div>
        </div>
        <hr class="mt-0">

        <div class="pb-2">
          <!-- form -->
          <vForm @submit="submit" :validation-schema="schema">
            <div class="row gy-2 px-2">
              <div class="col-6">
                <label class="align-middle">Date</label>
                <input
                    class="form-control invoice-edit-input date-picker flatpickr-input flatpickr-mobile"
                    tabindex="1"
                    type="date"
                    placeholder=""
                    v-model="formData.date"
                    required
                >
              </div>
              <div class="col-6">
                <label class="form-label" for="ref">Ref</label>
                <div class="input-group">
                  <vField
                      type="text"
                      name="ref"
                      v-model="formData.ref"
                      class="form-control add-credit-card-mask"
                      placeholder="Ref"
                  />
                  <ErrorMessage name="ref" class="invalid-feedback d-block qcont"/>
                </div>
              </div>
              <div class="col-6">
                <label class="form-label" for="short_description">Short Description</label>
                <vField
                    as="textarea"
                    v-model="formData.short_description"
                    name="short_description"
                    id="short_description"
                    class="form-control add-credit-card-mask"
                    placeholder="Short Description"
                />
                <ErrorMessage name="short_description" class="invalid-feedback d-block qcont"/>
              </div>
              <div class="col-6">
                <label class="form-label" for="full_description">Full Description</label>
                <vField
                    as="textarea"
                    v-model="formData.full_description"
                    name="full_description"
                    id="full_description"
                    class="form-control add-credit-card-mask"
                    placeholder="Full Description"
                />
                <ErrorMessage name="full_description" class="invalid-feedback d-block qcont"/>
              </div>
              <div v-if="type==='attachment'" class="col-12">
                <div class="mb-2">
                  <label for="attachment" class="form-label fw-bold">Attachment</label>
                  <BlobFileViewer :attachments="formData.attachments"/>
                  <FileUpload
                      :is-drag-active="true"
                      button-text="Upload Documents"
                      v-model="formData.attachments"
                  >
                  </FileUpload>
                </div>
              </div>
            </div>

            <div class="col-12 text-center">
              <hr class="mt-2">
              <button :disabled="loading" type="submit"
                      class="btn btn-primary me-1 mt-1 waves-effect waves-float waves-light">
                <div v-if="loading" class="spinner-border spinner-border-sm text-light" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
                {{ isEdit ? 'Update' : 'Submit' }}
              </button>
              <button @click.prevent="toggleModal" class="btn btn-outline-secondary mt-1 waves-effect">
                Cancel
              </button>
            </div>
          </vForm>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {ref} from "vue";
import FileUpload from "@/components/atom/FileUpload.vue";
import BlobFileViewer from "@/components/atom/BlobFileViewer.vue";

const props = defineProps({
  formData: Object,
  title: {
    type: String,
    default: ''
  },
  type: {
    type: String,
    default: ''
  },
  isEdit: {
    type: Boolean,
    default: false
  }
})

const $emit = defineEmits(['onSubmit', 'onClose'])

let isOpen = ref(false)
const loading = ref(false)
const schema = ref({})

const toggleModal = (isEdit = false) => {
  isOpen.value = !isOpen.value
  if (!isEdit) {
    $emit('onClose')
  }
}

const submit = () => {
  $emit("onSubmit")
}

defineExpose({toggleModal, loading})

</script>
