<template>
    <div>
        <div v-bind="getRootProps()" class="file-upload">
            <input v-bind="getInputProps()" />
            <p>Drag 'n' drop files here</p>
        </div>
        <button class="btn btn-outline-secondary mt-1" @click.prevent="open">{{ buttonText }}</button>
    </div>
</template>

<script>
import {ref}         from "vue";
import {useDropzone} from "vue3-dropzone";

export default {
    name: "FileUpload",
    props: {
        isDragActive: {
            type: Boolean,
            default: false
        },
        modelValue: {
            type: Array,
            default: () => []
        },
        buttonText: {
            type: String,
            default: "Select Files"
        },
        maxFiles: {
            type: Number,
            default: 5
        },
    },
    setup(props, {emit}) {
        let numberOfAttachment = 0;
        const onDrop = (acceptedFiles, rejectReasons) => {
            if (acceptedFiles.length > 0) {
                numberOfAttachment = props.modelValue.length + acceptedFiles.length;
                if( numberOfAttachment <= props.maxFiles ) {
                    props.modelValue.push(...acceptedFiles);
                    emit("update:modelValue", props.modelValue);
                } else {
                    alert(`You can only upload a maximum of ${props.maxFiles} files`);
                }
            }
        }

        const {
            getRootProps,
            getInputProps,
            open,
            ...rest
        } = useDropzone({onDrop});

        return {
            getRootProps,
            getInputProps,
            open,
            rest,
        }
    }
}
</script>

<style scoped>
    .file-upload{
        background-color: #f5f5f5;
        padding: 10px 20px;
        border-radius: 10px;
    }
</style>