<template>
    <div class="card card-height">
        <div class="table-responsive">
            <table class="table custom-table-padding">
                <thead>

                    <tr>
                        <th>Photo</th>
                        <th class="patient-details">Patient Details</th>
                        <th class="admission-no">
                            <span class="d-block">Admission No |</span>
                            <span class="d-block">Date & Time</span>
                        </th>
                        <th>Ward/Bed/Cabin</th>
                        <th>
                            <span class="d-block">Consultant</span>
                            <span>(Referred doctor)</span>
                        </th>
                        <th>Admitted By</th>
                        <th class="action-th" colspan="2">Action</th>
                        <th class="action-th" v-if="$route.name !== 'ipd-station'">Statement</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, i) in tableItems" :key="i">

                        <td>
                            <img :src=item.patient_photo alt="avatar" class="profile-pic">
                        </td>

                        <td>
                            <div class="col">
                                <strong>
                                    <h4>{{ item.patient_name }} {{ formatPatientInfo(item) }} </h4>
                                </strong>
                                {{ formatPatientAdditionalInfo(item) }}
                            </div>
                        </td>

                        <td>
                            <div class="col">
                                <h5 class="text-bold">{{ item.admission_no }}</h5>
                               <span>{{ item.admission_date }}</span>
                            </div>
                        </td>

                        <td>
                            {{ item.bed }}
                        </td>

                        <td>
                            {{ item.consultant }}
                        </td>

                        <td>
                            {{ item.admitted_by }}
                        </td>
                        <td>
                            <div class="action">
                                <button @click="goToDoctorOrder(item)" class="btn btn-primary btn-sm">
                                    <i class="fas fa-plus"></i>
                                    Doctor's order
                                </button>
                            </div>
                        </td>
                        <slot v-if="$route.name !== 'ipd-station'">
                            <td>
                                <div class="action">
                                    <button @click="goToOPDBillList(item.id)" class="btn btn-primary btn-sm">
                                      <i class="fas fa-plus"></i>
                                      Bill
                                    </button>
                                </div>
                            </td>
                            <td>
                                <button class="btn btn-success btn-sm d-flex mx-auto"
                                    @click="goToIPDStatementReport(item.id)"
                                    style="cursor: pointer;"
                                >
                                  <i class="fas fa-file-pdf"></i>
                                </button>
                            </td>
                        </slot>
                    </tr>
                </tbody>
            </table>
        <p v-if="!tableItems.length" class="text-center mt-5">No data</p>
    </div>
</div>
</template>

<script setup>
    import { useRoute, useRouter } from "vue-router";
    import { dateOfBirthConvertToAge } from '@/services/utils/global.js'

    const $route = useRoute();
    const $router = useRouter();
    import { lowerCase } from 'lodash'
    const props = defineProps({
        tableItems: {
            type: Array,
        }
    })

    const goToDoctorOrder = (item) => {
        const params = $route.params;
        params.serviceId = item.service_general_id;

        const query = $route.query;
        query.admissionId = item.id;

        $router.push({name: 'ipd-doctors-order',
            params: item.service_general_id,
            query: query,
        })
    }

    const goToOPDBillList = (id) => {
        const params = $route.params;
        const query = $route.query;
        params.ipdRegisterId = id;
        query.pageId = params.pageId

        $router.push({name: 'ipd-bill-list',
            params: params,
            query: query,
        })
    }

    const goToIPDStatementReport = (id) => {
        const params = $route.params;
        const query = $route.query;
        query.id = id;

        $router.push({name: 'ipd-statement',
            params: params,
            query: query,
        })
    }

    const formatPatientInfo = (item) => {
        let text = '';
        if(!item.patient_birthday && !item.patient_gender) return text;

        if(item.patient_birthday){
            const { year } = dateOfBirthConvertToAge(item.patient_birthday);
            text =+ year + 'years';
        }

        if(item.patient_birthday && item.patient_gender) text += ', ';

        if(item.patient_gender){
            const gender = lowerCase(item.patient_gender);
            text += `${gender}`;
        }

        return `(${text})`;
    }

    const formatPatientAdditionalInfo = (item) => {
        let text = '';
        if(item.mobile) text += item.mobile;
        if((item.mobile && item.id)) text += ' | ';
        if(item.serial_no) text += item.serial_no;
        return text;
    }
</script>

<style scoped>

.admission-no{
    width: 15%;
}
.patient-details{
    width: 25%;
}
.print-btn{
    margin-right: 5px;
}
.action-th{
    width: 15%;
    text-align: center;
}
.action{
    text-align: center;
}

.profile-pic{
    width: 65px;
}
</style>
