<template>
  <div class="card">
    <div class="bg-blue-light p-2">
      <TitleButton :showBtn="false" title="Party Ledger" />

      <DateQuerySetter
          :inEnoughSpace="false"
          btnTitle="Go"
          @onClickGo="onClickGo"
      >
        <div v-if="!$route.query.contactId" class="col-12 col-sm-6 col-md-4 col-lg-3">
          <label for="colFormLabel" class="col-form-label">Party: </label>
          <v-select
              placeholder="Select Party"
              class="w-100"
              :options="contactProfiles"
              label="name"
              :reduce="(name) => name.id"
              v-model="contact_profile_id"
          />
        </div>
        <div class="col-12 col-sm-6 col-md-4 col-lg-3">
          <label for="colFormLabel" class="col-form-label"
          >Select Account Head:
          </label>
          <v-select
              multiple
              v-model="headId"
              :options="accountHeads"
              label="name"
              :reduce="(name) => name.id"
              placeholder="Select head"
          />
        </div>
        <div class="col-12 col-sm-6 col-md-4 col-lg-3">
          <label
              for="colFormLabel"
              class="col-form-label"
          >Business</label
          >
          <v-select
              placeholder="Select Business"
              class="w-100"
              :options="businesses"
              label="name"
              :reduce="(name) => name.id"
              v-model="business_id"
          />
        </div>

        <div class="col-12 col-sm-6 col-md-4 col-lg-3">
          <label
              for="colFormLabel"
              class="col-form-label"
          >Voucher Type</label
          >
          <select class="form-control" v-model="voucher_type">
            <option :value="null" selected>Select voucher type</option>
            <option
                :value="voucherType"
                v-for="voucherType in Object.keys(homeData.voucher_type)"
            >
              {{ homeData.voucher_type[voucherType] }}
            </option>
          </select>
        </div>

        <div class="col-12 col-sm-6 col-md-4 col-lg-3">
          <label
              for="colFormLabel"
              class="col-form-label"
          >txn Type</label
          >
          <select class="form-control" v-model="txn_type">
            <option :value="null" selected>Select txn type</option>
            <option :value="txnType" v-for="txnType in typeKeys">
              {{ homeData.txn_type[txnType] }}
            </option>
          </select>
        </div>
      </DateQuerySetter>
    </div>
    <div class="card mt-1">
      <div class="d-flex justify-content-between px-2">
        <div class="col-md-8">
          <table>
            <tr>
              <td>Account Name </td>
              <td> : <strong>{{ accountName }}</strong></td>
            </tr>
            <tr>
              <td>Report period </td>
              <td> : <strong>{{ start }} to {{ end }}</strong></td>
            </tr>
            <tr>
              <td>Business </td>
              <td> : <strong>{{ business }}</strong></td>
            </tr>
            <tr>
              <td>Voucher Type </td>
              <td> : <strong>{{ homeData.voucher_type[voucher_type] }}</strong></td>
            </tr>
            <tr>
              <td>txn Type </td>
              <td> : <strong>{{ homeData.txn_type[txn_type] }}</strong></td>
            </tr>
          </table>
        </div>

        <div>
          <table class="head">
            <tr>
              <td>Opening Balance :</td>
              <td>
                <strong>{{
                    ledgerData.opening_balance
                    ? commaFormat(ledgerData.opening_balance)
                    : 0.0
                  }}</strong>
              </td>
            </tr>
            <tr>
              <td>Total Debit :</td>
              <td>
                <strong>{{
                    ledgerData.total_debit
                    ? commaFormat(ledgerData.total_debit)
                    : 0.0
                  }}</strong>
              </td>
            </tr>
            <tr>
              <td>Total Credit :</td>
              <td>
                <strong>{{
                    ledgerData.total_credit
                    ? commaFormat(ledgerData.total_credit)
                    : 0.0
                  }}</strong>
              </td>
            </tr>
            <tr>
              <td>Closing Balance :</td>
              <td>
                <strong>{{
                    ledgerData.closing_balance
                    ? commaFormat(ledgerData.closing_balance)
                    : 0.0
                  }}</strong>
              </td>
            </tr>
          </table>
        </div>
      </div>

      <div class="col-12 mt-2">
        <div class="d-flex justify-content-end px-2">
          <button
              @click="goToPrint"
              class="btn btn-outline-secondary btn-sm btn-download-invoice waves-effect mx-1"
          >
            Print
          </button>

          <button
              @click="exportTable"
              class="btn btn-outline-secondary btn-sm btn-download-invoice waves-effect"
          >
            Export
          </button>
        </div>
      </div>
      <LedgerTable
          :openingBalance="ledgerData.opening_balance"
          :closingBalance="ledgerData.closing_balance"
          :totalDebitBalance="ledgerData.total_debit"
          :totalCreditBalance="ledgerData.total_credit"
          :currentPage="ledgerData?.ledgers?.current_page"
          :lastPage="ledgerData?.ledgers?.last_page"
          :ledgers="ledgers"
      />
    </div>
    <div class="px-2">
      <Pagination
          :offset="offset"
          @onPageChange="onPageChange"
          ref="pagination"
      />
    </div>
    <Loader v-if="reportLoading" />
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import handleReport from "@/services/modules/accountingReport";
import { inject } from "vue";

import TitleButton from "@/components/atom/TitleButton";
import Loader from "@/components/atom/LoaderComponent";
import DateQuerySetter from "@/components/atom/DateQuerySetter";
import LedgerTable from "@/components/molecule/company/ledger/LedgerTable";
import Pagination from "@/components/atom/Pagination";
import figureFormatter from "@/services/utils/figureFormatter";
import handleContact from "@/services/modules/contact";
import handleCBusinesses from "@/services/modules/businesses";
import handleCompany from "@/services/modules/company";
import pdfPrinter from '@/services/utils/pdf/partyLedger';

export default {
  name: "PartyLedger",

  components: {
    TitleButton,
    DateQuerySetter,
    Pagination,
    LedgerTable,
    Loader,
  },

  props: {
    contactInfo: {
      type: Object
    }
  },

  data: () => ({
    accountHeads: [],
    ledgerData: {},
    headId: null,
    homeData: {
      txn_type: {},
      voucher_type: {},
    },
    contactProfiles: [],
    businesses: [],
    contact_profile_id: null,
    business_id: null,
    voucher_type: null,
    txn_type: null,
    offset: 20,
    companyInfo: {}
  }),

  computed: {
    companyId() {
      return this.$route.params.companyId;
    },
    start() {
      return this.$route.query.start;
    },
    end() {
      return this.$route.query.end;
    },
    page() {
      return this.$route.query.page;
    },
    ledgers() {
      return this.ledgerData.ledgers?.data ? this.ledgerData.ledgers.data : [];
    },
    accounts() {
      return this.ledgerData.accounts ? this.ledgerData.accounts : {};
    },
    token() {
      return localStorage.getItem("token");
    },
    typeKeys() {
      return Object.keys(this.homeData.txn_type);
    },
    accountName() {
      return this.headId ? this.headId.map(id => {
        const matchedItem = this.accountHeads.find(item => item.id === id);
        return matchedItem ? matchedItem.name : null;
      }).filter(name => name !== null).join(', ') : null;
    },
    business() {
      const matchedItem = this.businesses ? this.businesses.find(item => item.id === this.business_id) : null;
      return matchedItem ? matchedItem.name : null;
    },
    contactId() {
      return this.$route.query.contactId;
    }
  },

  methods: {
    ...mapMutations({
      setPaginationData: "setPaginationData",
      resetPagination: "resetPagination",
    }),

    goToPrint() {
      this.ledgerData.accountName = this.accountName;
      this.ledgerData.business = this.business
      this.ledgerData.voucherType = this.voucher_type ? this.homeData.voucher_type[this.voucher_type] : null;
      this.ledgerData.txnType = this.voucher_type ? this.homeData.txn_type[this.txn_type] : null;
      this.ledgerData.startDate = this.start;
      this.ledgerData.endDate = this.end;

      this.exportToPDF(this.companyInfo, this.ledgerData, this.contactInfo)
    },

    exportTable() {
      if(!this.contact_profile_id) {
        this.showError("Party is required");
        return;
      }
      let query = this.getQuery();
      let url = `${process.env.VUE_APP_BASE_URL}/export/reports/ledger/party${query}&_token=${this.token}`;
      let a = document.createElement("a");
      a.setAttribute("href", url);
      a.click();
    },

    setPagination() {
      if (!this.ledgerData?.ledgers?.data) return;
      this.setPaginationData({
        records: this.ledgerData.ledgers.total,
        perPage: this.ledgerData.ledgers.per_page,
        currentPage: this.ledgerData.ledgers.current_page,
      });
    },
    onClickGo() {
      this.setPaginationData({});
      let routeQuery = Object.assign({}, this.$route.query);
      routeQuery.page = 1;
      this.$router.push({ path: this.$route.path, query: routeQuery });
      setTimeout(() => {
        this.getDateWiseLedger();
      }, 100);
    },

    onPageChange(page) {
      let routeQuery = Object.assign({}, this.$route.query);
      routeQuery.page = page;
      this.$router.push({ path: this.$route.path, query: routeQuery });
      setTimeout(() => {
        this.getDateWiseLedger();
      }, 100);
    },

    check() {
      this.$refs.pagination.setPage(2);
    },

    async getCompanyInfo() {
      try{
        let res = await this.fetchCompanyInfo(this.$route.params.companyId)
        if (!res.status) {
          this.showError(res.message);
          return;
        }
        this.companyInfo = res.data;
      } catch (err) {
        if (!err.response) {
          this.showError("Something is wrong. Check your connectivity!!");
        }
        if (err.response) {
          this.showError(err.response.message);
        }
      }
    },

    async getAccountHeads() {
      // return;
      try {
        this.reportLoading = true;
        let res = await this.fetchAccountHeads(this.$route.params.companyId);
        if (!res.status) {
          this.resetPagination();
          this.showError(res.message);
        }
        if (res.status) {
          this.accountHeads = res.data;
        }
      } catch (err) {
        if (!err.response) {
          this.showError("Something is wrong. Check your connectivity!!");
        }
        if (err.response) {
          this.showError(err.response.message);
        }
      } finally {
        this.reportLoading = false;
      }
    },

    async getHome() {
      try {
        let res = await this.fetchHome();
        if (!res.status) {
          //this.showError(res.message)
          this.resetPagination();
          this.homeData = {};
        }
        if (res.status) {
          this.homeData = res.data;
        }
      } catch (err) {
        if (err.response) {
          this.showError(err.response.message);
        }
      }
    },
    async getContactProfiles() {
      try {
        let companyId = this.$route.params.companyId;
        let contactId = "";
        let q = "";
        let query = "?company_id=" + companyId + "&q=" + q + "&contact_id" + contactId;
        let res = await this.fetchContactProfiles(query);

        if (res.status) {
          this.contactProfiles = res.data;
        }
      } catch (err) {
        if (err.response) {
          this.showError(err.response.message);
        }
      } finally {
        this.loading = false;
      }
    },
    async getBusinesses() {
      try {
        let companyId = this.$route.params.companyId;
        let res = await this.fetchBusinessList("?company_id=" + companyId);

        if (res.status) {
          this.businesses = res.data;
        }
      } catch (err) {
        if (err.response) {
          this.showError(err.response.message);
        }
      } finally {
        this.loading = false;
      }
    },

    getQuery() {
      let query = "?company_id=" + this.companyId;
      if (!this.start && !this.end) return query;
      if (this.start) query += "&start_date=" + this.start;
      if (this.end) query += "&end_date=" + this.end;
      if (this.page) query += "&page=" + this.page;
      if (this.headId && this.headId.length > 0) query += "&head_id=" + this.headId;
      if (this.contact_profile_id)
        query += "&contact_profile_id=" + this.contact_profile_id;
      if (this.business_id) query += "&business_id=" + this.business_id;
      if (this.txn_type) query += "&txn_type=" + this.txn_type;
      if (this.voucher_type) query += "&voucher_type=" + this.voucher_type;
      query += "&offset=" + this.offset;
      return query;
    },

    async getDateWiseLedger() {
      if(!this.contact_profile_id) {
        //this.showError('Please select party first');
        return;
      }
      let query = this.getQuery();
      try {
        this.reportLoading = true;
        let res = await this.getPartyLedger({
          headId: this.headId,
          query: query,
        });
        if (!res.status) {
          this.showError(res.response.message);
        }
        if (res.status) {
          this.ledgerData = res.data;
          this.setPagination();
        }
      } catch (err) {
        if (!err.response) {
          this.showError("Something is wrong. Check your connectivity!!");
        }
        if (err.response?.data) {
          this.showError(err.response.data.message);
          return;
        }
      } finally {
        this.reportLoading = false;
      }
    },
  },

  setup() {
    const showError = inject("showError");
    const showSuccess = inject("showSuccess");
    // const store = useStore()

    const {
            fetchDaybook,
            reportLoading,
            fetchAccountHeads,
            fetchHome,
            getPartyLedger,
          } = handleReport();

    const { commaFormat } = figureFormatter();

    const { fetchContactProfiles } = handleContact();

    const { fetchBusinessList } = handleCBusinesses();

    const { fetchCompanyInfo } = handleCompany();

    const { exportToPDF } = pdfPrinter();

    return {
      // journals: computed(() => store.state.journal.journals),
      fetchContactProfiles,
      fetchBusinessList,
      fetchHome,
      fetchDaybook,
      reportLoading,
      fetchAccountHeads,
      showError,
      showSuccess,
      getPartyLedger,
      commaFormat,
      fetchCompanyInfo,
      exportToPDF
    };
  },
  watch: {
    '$route.query.contactId': function(contactId) {
      this.contact_profile_id = contactId;
    }
  },
  created() {
    this.contact_profile_id = this.$route.query.contactId;
  },
  mounted() {
    this.resetPagination();
    this.getAccountHeads();
    this.getHome();
    if(! this.contactId) {
      this.getContactProfiles();
    }
    this.getBusinesses();
    this.getCompanyInfo();
    if (this.$route.query.headId) {
      this.headId = parseInt(this.$route.query.headId);
      this.onClickGo();
    }
  },
};
</script>

<style scoped>
.head td {
  text-align: right;
}
</style>
