import hospitalPdfPrinter from '@/services/utils/hospitalPdfPrinter';
import handleBarcode from "@/services/modules/barcode";
import { useStore } from "vuex";
import { addFrontBaseUrl } from '@/services/utils/global';

const hospitalPdfPrinterHelper = () => {
    const { exportToPDF } = hospitalPdfPrinter();
    const { fetchBarcode } = handleBarcode();
    const store = useStore();
    const user = store.state.user.user;
    var barcode = '', qrcode = '', barcodePatient;
    const publicToken = localStorage.getItem('X-Public-Token')

    const generatePdf = async (company, data, btnTitle, titleByType = false, isSalesMaster = false, isBillType = false, isRedirect = false) => {
        let qrcodeData = JSON.stringify({
            ref_no: data.bill_number,
            ref_date: data.date,
            created_by: data.created_by_name,
            created_date_time: formatDate(new Date())
        })

        const url = addFrontBaseUrl('/company/' + company.id + '/pdf-download/' + data.id + '?public_token=' + publicToken);
        var barCodeQuery = `?code=${data.bill_number}&qrcode=${ isRedirect ? encodeURIComponent(url) : qrcodeData }`;
        var barCodePatientQuery = `?code=${data.contact_profile ? data.contact_profile.patient_id : data.contact_profile_id}`;

        await Promise.all([
            fetchBarcode(barCodeQuery).then( (res) => {
                barcode = res.barcode;
                qrcode = res.qrcode;
            }),
            fetchBarcode(barCodePatientQuery).then( (res) => {
                barcodePatient = res.barcode;
            })
        ]).then(() => {
            exportToPDF(company, data, barcode, qrcode, barcodePatient, (user ? user.name : ''), btnTitle, titleByType, isSalesMaster, isBillType, isRedirect)
        }).catch((e) => {
            console.log('Something went wrong');
        })
    }

    const formatDate = (date) => {
        var formattedDate = date.toISOString().split('T')[0];
        var hours = date.getHours();
        var minutes = date.getMinutes();
        var amOrPm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12 || 12;
        var formattedTime = hours + ':' + (minutes < 10 ? '0' : '') + minutes + ' ' + amOrPm;
        return formatToDdMmYy(formattedDate) + ' ' + formattedTime
    }

    const formatToDdMmYy = (date) => {
        var dat = date.split('-');
        return `${dat[2]}-${dat[1]}-${dat[0]}`;
    }

    return{
        generatePdf
    }
}

export default hospitalPdfPrinterHelper;