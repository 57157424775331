<template>
    <div class="card" style="min-height: calc(100vh - 180px)">
        <div class="bg-blue-light p-1">
            <TitleButton
                btnTitle="Add New"
                :showBtn="false"
                :showAddNew="true"
                title="Pharmacy Invoice list"
                @onClickAddNewButton="navigateToAddPage"
            />
        </div>

        <div class="my-2 px-2">
            <DateQuerySetter
                btnTitle="Go"
                @onClickGo="onClickGo"
            >

                <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                    <label for="colFormLabel" class="col-form-label">Patient: </label>
                    <input type="text" class="form-control" name="name" v-model="contactInfo.full_name" disabled>
                </div>
            </DateQuerySetter>
        </div>
        <div class="col-12 px-2 mt-2">
            <PharmacyListTable :purchase="purchaseList" route_name="sales-invoice-edit"/>
        </div>
        <div class="px-2"><Pagination :offset="offset" @onPageChange="onPageChange" ref="pagination"/></div>
    </div>
</template>

<script setup>
import DateQuerySetter from '@/components/atom/DateQuerySetter'
import { ref, onMounted, computed, watch } from 'vue'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
import handlePurchase from '@/services/modules/purchase'
import TitleButton from '@/components/atom/TitleButton'
import Pagination from '@/components/atom/Pagination'
import PharmacyListTable from "@/components/molecule/company/hospital/billing/pharmacy/ListTable.vue";

const router = useRouter()
const store = useStore()
let offset = ref(20)
let purchaseList = ref([])
const route = useRoute()

const props = defineProps({
    contactInfo: {
        type: Object,
        default: {}
    }
})

const {fetchSalesList} = handlePurchase()

const companyId = computed(() => {
    return route.params.companyId
})
const startDate = computed(() => {
    return route.query.start
})
const endDate = computed(() => {
    return route.query.end
})
const page = computed(() => {
    return route.query.page
})

const patientId = computed(() => props.contactInfo.id);

watch(patientId, () => {
    onClickGo();
})

function navigateToAddPage() {
    router.push({name: 'invoice-hospital', params: route.params, query:route.query})
}

function getQuery () {
    let query = '?company_id=' + companyId.value
    query += '&sale_type=pharmacy_invoice'
    query += '&is_belong_to_user=1'
    if(!!patientId.value) query += '&contact_profile_id=' + patientId.value
    if(!startDate.value && !endDate.value) return query
    if(startDate.value) query += '&start_date='+startDate.value
    if(endDate.value) query += '&end_date='+endDate.value
    if(page.value) query += '&page='+page.value
    query += '&offset='+offset.value
    return query
}

function onClickGo() {
    fetchSalesList(getQuery())
        .then(res => {
            if(! res.status) {
                purchaseList.value = [];
                return
            }
            purchaseList.value = res.date.data
            setPagination(res.date)
    })
}

function setPagination(data) {
    if(!data.data.length) return
    store.commit('setPaginationData', {
        records: data.total,
        perPage: data.per_page,
        currentPage: data.current_page,
    })
}

function onPageChange(page) {
    let routeQuery = Object.assign({}, route.query)
    routeQuery.page = page
    router.push({path: route.path, query: routeQuery})
    onClickGo()
}
</script>
