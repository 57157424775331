<template>
    <div>
      <div class="card">
        <div class="bg-blue-light p-1">
          <TitleButton
            btnTitle="Add Bill"
            :showBtn="false"
            :showAddNew="true"
            title="OPD Billing List"
            @onClickAddNewButton="onClickAddNewButton"
          />
        </div>
  
        <div class="my-2 px-2">
          <DateQuerySetter
            btnTitle="Go"
            @onClickGo="getOPDBillings"
          >
            <div class="col-12 col-sm-6 col-md-5 col-lg-4">
              <label for="colFormLabel" class="col-form-label">Patient</label>
              <input type="text" class="form-control" name="name" v-model="contactInfo.full_name" disabled>
            </div>
          </DateQuerySetter>
        </div>
  
        <div class="col-12 px-2 my-2">
          <ListTable @onEdit="handleEdit" :bills="bills" :company="company"/>
        </div>
  
        <div class="px-2 position-absolute bottom-0">
          <Pagination
            :offset="offset"
            @onPageChange="onPageChange"
            ref="pagination"
          />
        </div>
      </div>
      <Loader v-if="loading"/>
    </div>
  </template>
  
  <script setup>
  import TitleButton from '@/components/atom/TitleButton.vue';
  import DateQuerySetter from '@/components/atom/DateQuerySetter.vue';
  import ListTable from '@/components/molecule/company/hospital/OpdBillingListTable.vue';
  import {useRoute, useRouter} from "vue-router";
  import handleHospitalBilling from "@/services/modules/hospital/billing";
  import {computed, inject, onMounted, ref, watch} from "vue";
  import Loader from "@/components/atom/LoaderComponent.vue";
  import Pagination from "@/components/atom/Pagination.vue";
  import {useStore} from "vuex";
  import handleCompany from "@/services/modules/company";
  import handleContact from "@/services/modules/contact";
  import {useAsyncDropdownHelper} from "@/services/utils/asyncDropdownHelper";
  
  const $route = useRoute();
  const $router = useRouter();
  const $store = useStore();
  
  const showError = inject('showError');
  const showSuccess = inject('showSuccess');
  const {fetchOPDBillings} = handleHospitalBilling();
  const {fetchCompanyInfo} = handleCompany();
  const {fetchContactProfiles} = handleContact()
  const {formatPatientLabel} = useAsyncDropdownHelper()
  
  const offset = ref(10);
  const loading = ref(false);
  const bills = ref([])
  const doctorId = ref(null)
  const patient = ref(null)
  const status = ref(null)
  const company = ref({})

  const props = defineProps({
    contactInfo: {
        type: Object,
        default: {}
    }
  })

  // computed
  const start = computed(() => $route.query.start);
  const end = computed(() => $route.query.end);
  const page = computed(() => $route.query.page);
  const companyId = computed(() => $route.params.companyId);
  const patientId = computed(() => props.contactInfo.id);

  watch(patientId, () => {
    getOPDBillings();
  })
  
  const additionalQuery = {
    type: "patient"
  }

  //methods
  const setPaginationData = (data) => {
    $store.commit('setPaginationData', data)
  };
  const resetPagination = () => {
    $store.commit('resetPagination')
  };
  
  const onPageChange = async (page) => {
    let routeQuery = Object.assign({}, $route.query)
    routeQuery.page = page
    await $router.push({path: $route.path, query: routeQuery})
    await getOPDBillings()
  }
  const setPagination = (data) => {
    setPaginationData({
      records: data.total,
      perPage: data.per_page,
      currentPage: data.current_page,
    })
  }
  
  const onClickAddNewButton = () => {
    let params = {
      companyId: $route.params.companyId,
      moduleId: $route.params.moduleId,
      menuId: $route.params.menuId,
      pageId: $route.params.pageId,
    }
    $router.push({
      name: 'opd-billing',
      params: params,
      query: $route.query
    })
  }
  const getQuery = () => {
    let query = '?company_id=' + companyId.value
    query += '&patient_id=' + patientId.value
    if (!start && !end) return query
    if (start) query += '&start_date=' + start.value
    if (end) query += '&end_date=' + end.value
    query += '&offset=' + offset.value
    if (page) query += '&page=' + page.value
    if (doctorId.value) query += '&doctor_id=' + doctorId.value
    if (status.value) query += '&status=' + status.value
    return query
  };
  
  async function getOPDBillings() {
    loading.value = true;
    try {
      const res = await fetchOPDBillings(getQuery());
      if (!res.status) {
        bills.value = []
        return $store.commit("resetPagination")
      }
      bills.value = res.data.data;
      if (bills.value.length > 0) {
        setPagination(res.data)
      }
    } catch (err) {
      if (err.response) {
        showError(err.response.message)
      }
    } finally {
      loading.value = false;
    }
  }
  
  const handleEdit = (item) => {
    console.log(item, 'item')
  }
  
  onMounted(() => {
    getOPDBillings()
    fetchCompanyInfo(companyId.value).then(res=> {
      if(res.data) company.value = res.data;
    })
  })
  </script>
  