<template>
  <div class="card card-height">
    <div class="table-responsive">
      <table class="table">
        <thead>
        <tr>
          <th>Date</th>
          <th>Bill No</th>
          <th>Patient Name</th>
          <th>Patient ID</th>
          <th>Mobile No</th>
          <th>Consultant Name</th>
          <th class="text-right">Bill Amount</th>
          <th>Action</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(item, i) in bills" :key="i">
          <td> {{ item.start_date_time }}</td>
          <td> {{ item.sale_master?.bill_number }}</td>
          <td> {{ item.contact_profile?.full_name }}</td>
          <td> {{ item.contact_profile?.serial_no }}</td>
          <td> {{ item.contact_profile?.mobile_no }}</td>
          <td class="text-right"> {{ item.service_resource?.name }}</td>
          <td> {{ commaFormat(item.sale_master?.total_amount) }}</td>
          <td>
            <button class="btn btn-secondary btn-sm"
              @click="goToPrint(item.sale_master_id)"
              style="cursor: pointer; margin-left: 10px"
            >
              <i class="fas fa-print"></i>
            </button>
          </td>
        </tr>

        </tbody>
      </table>
      <p v-if="!bills.length" class="text-center mt-5">No data</p>
    </div>
  </div>
</template>

<script setup>
import figureFormatter from "@/services/utils/figureFormatter";
import handlePurchase from "@/services/modules/purchase";
import {ref} from "vue";
import hospitalPdfPrinterHelper from "@/services/utils/hospitalPdfPrinterHelper";
import {useRoute} from "vue-router";

const props = defineProps({
  bills: Array,
  company: Object,
})
const {commaFormat} = figureFormatter();
const {fetchSalesBill} = handlePurchase();
const { generatePdf } = hospitalPdfPrinterHelper();
const $emit = defineEmits(['onEdit'])
const $route = useRoute()
const invoiceRes = ref({})
const goToEdit = (item) => {
  $emit("onEdit", item)
}

const goToPrint = (id) => {
  const companyQuery = `?company_id=${$route.params.companyId}`;

  fetchSalesBill(id, companyQuery).then( (res) => {
    if( res ){
      invoiceRes.value = res.data;
      if (invoiceRes.value.service_general && invoiceRes.value.service_general.service_resource) {
          invoiceRes.value.consultant = invoiceRes.value.service_general.service_resource.name ?? ''
      }
    }
  }).then( () => {
    generatePdf(props.company, invoiceRes.value, 'BILL', true, true, true)
  }).catch( (err) => {
    console.log(err);
  })
}
</script>
<style scoped>
.card--height {
  min-height: 200px
}
</style>
