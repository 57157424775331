import pdfMake from 'pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { useStore } from "vuex";
import figureFormatter from "@/services/utils/figureFormatter";
import pdfHeaderFooter from '@/services/utils/pdf/pdfHeaderFooter';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const pdfPrinter = () => {
    const store = useStore();
    const { commaFormat } = figureFormatter();
    const { getHeader, getFooter } = pdfHeaderFooter();

    const userName = store.state.user.user.name;

    const exportToPDF = (company, ledgerData, contactInfo) => {
        var doc = {
            pageSize: 'A4',
            pageMargins: [ 25, 100, 25, 80 ],
            header: getHeader(company),
            footer: getFooter(userName),
            content: getContent(ledgerData, contactInfo),
            
            styles : {
                header: {
                    fontSize: 24,
                }
            },

            defaultStyle: {
                color: 'black',
                fontSize: 10
            },

            info: {
                title: 'DischaregeOrder'
            }
        }

        pushIntoTable(doc, ledgerData.ledgers.data)

        const pdfDocGenerator = pdfMake.createPdf(doc);
        pdfDocGenerator.open();
    }

    const getContent = (ledgerData, contactInfo) => {
        return [
            {
                text: 'Party Ledger',
                style: 'header',
                alignment: 'center',
                margin: [0, 0, 20, 10],
                bold: true,
                color: 'black',
                decoration: 'underline'
            },

            {
                margin: [0, 20, 0, 0],
                alignment: 'justify',
                columns: [
                    {
                        width: '50%',
                        stack: [
                            {
                                text: `Party Name & ID: ${contactInfo.full_name} & ${contactInfo.serial_no}`
                            },
                            
                            {
                                text: `Mobile No: ${contactInfo.mobile_no}`
                            },

                            {
                                text: `${ledgerData.accountName ? 'Account Head: ' + ledgerData.accountName : ''}`
                            },

                            {
                                text: `${ledgerData.business ? 'Business: ' + ledgerData.business : ''}`
                            },

                            {
                                text: `${ledgerData.voucherType ? 'Voucher Type: ' + ledgerData.voucherType : ''}`
                            },

                            {
                                text: `${ledgerData.txnType ? 'txn Type: ' + ledgerData.txnType : ''}`
                            },

                            {
                                text: `Report Date: ${ledgerData.startDate} to ${ledgerData.endDate}`
                            }
                        ]
                    },

                    {
                        alignment: 'right',
                        stack: [
                            {
                                text: `Opening Balance: ${ledgerData.opening_balance
                                        ? commaFormat(ledgerData.opening_balance)
                                        : 0.0}`
                            },

                            {
                                text: `Total Debit: ${ledgerData.total_debit
                                        ? commaFormat(ledgerData.total_debit)
                                        : 0.0}`
                            },

                            {
                                text: `Total Credit: ${ledgerData.total_credit
                                        ? commaFormat(ledgerData.total_credit)
                                        : 0.0}`
                            },

                            {
                                text: `Closing Balance: ${ledgerData.closing_balance
                                        ? commaFormat(ledgerData.closing_balance)
                                        : 0.0}`
                            }
                        ]
                    },
                ]
            },

            {
                style: 'tableExample',
                margin: [0, 10, 0, 0],
                table: {
                    body: []
                },
                layout: {
                    fillColor: function (rowIndex, node, columnIndex) {
                        return (rowIndex === 0) ? '#f3f2f7' : null;
                    },
                    hLineWidth: function (i, node) {
                        return 0.5;
                    },
                    vLineWidth: function (i, node) {
                        return 0.5;
                    }
                }
            },
        ]
    }

    const pushIntoTable = (doc, tableItems) => {
        const tableHeader = getTableHeader();
        doc.content[2].table.body.push(tableHeader);
        tableItems.forEach(item => {
            const rowData = getRowData(item);
            return doc.content[2].table.body.push(rowData);
        });
        const getLastRow = getTotal(tableItems)
        doc.content[2].table.body.push(getLastRow);
    }

    const getTotal = (tableItems) => {
        const { totalDebit, totalCredit, totalBal } = tableItems.reduce((acc, curr) => ({
            totalDebit: acc.totalDebit + curr.debit,
            totalCredit: acc.totalCredit + curr.credit,
            totalBal: acc.totalBal + curr.balance
        }), { totalDebit: 0, totalCredit: 0, totalBal: 0 });

        const lastRow = [
            {
                text: 'TOTAL',
                alignment: 'right',
                colSpan: 5
            },

            '', '', '', '',

            {
                text: commaFormat(totalDebit),
                alignment: 'right'
            },

            {
                text: commaFormat(totalCredit),
                alignment: 'right'
            },

            {
                text: commaFormat(totalBal),
                alignment: 'right'
            }
        ]

        return lastRow
    }

    const getTableHeader = () => {
        const tableHeader = [
            { text: 'Date' },
            { text: 'Txn Type' },
            { text: 'Txn No' },
            { text: 'Voucher No' },
            { text: 'Accounts Head' },
            { text: 'Debit', alignment: 'right' },
            { text: 'Credit', alignment: 'right' },
            { text: 'Balance', alignment: 'right' }
        ]

        return tableHeader
    }
    const getRowData = (item) => {
        const tableRow = [ 
            { text: item.date },
            { text: item.txn_type },
            { text: '' },
            { text: item.voucher_no },
            { text: item.credit_account_head },
            { text: commaFormat(item.debit), alignment: 'right' },
            { text: commaFormat(item.credit), alignment: 'right' },
            { text: commaFormat(item.balance), alignment: 'right' }
        ] 

        return tableRow
    }

    return {
        exportToPDF
    }

}

export default pdfPrinter;