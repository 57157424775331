import moneyReceipt from '@/services/utils/pdf/moneyReceipt';
import handleBarcode from "@/services/modules/barcode";
import { useStore } from "vuex";
import useDate from '@/services/utils/day';
import figureFormatter from "@/services/utils/figureFormatter";

const moneyReceiptHelper = () => {
    const { exportToPDF } = moneyReceipt();
    const { fetchBarcode } = handleBarcode();
    const { formatDate } = useDate();
    const { commaFormat } = figureFormatter();
    const store = useStore();
    const userName = store.state.user.user.name;
    var barcode = '', qrcode = '', barcodePatient;

    const generatePdf = (company, data) => {
        let qrcodeData = JSON.stringify({
            ref_no: data.voucher_no,
            ref_date: data.receipt_date,
            created_by: userName,
            created_date_time: formatDate(new Date())
        })

        var barCodeQuery = `?code=${data.bill_number}&qrcode=${qrcodeData}`;
        var barCodePatientQuery = `?code=${data.contact.serial_no}`;

        Promise.all([
            fetchBarcode(barCodeQuery).then( (res) => {
                barcode = res.barcode;
                qrcode = res.qrcode;
            }),
            fetchBarcode(barCodePatientQuery).then( (res) => {
                barcodePatient = res.barcode;
            })
        ]).then(() => {
            exportToPDF(company, data, barcode, qrcode, barcodePatient, userName)
        }).catch((e) => {
            console.log(e);
        })
    }

    const formatMoneyReceiptData = (data) => {

        let formatData = [
            getTableHead()
        ];

        formatData = formatData.concat(getTableRow(data), getTotals(data));

        return formatData;
    }

    const getTableRow = (data) => {
       return data.map((item) => {
            return [
                { text: item.receipt_date },
                { text: item.receipt_number },
                { text: item.voucher_number },
                { text: item.bill_numbers },
                { text: item.receipt_by },
                { text: item.mode_of_payment },
                { text: item.type },
                { text: item.patient_name },
                { text: item.patient_id },
                { text: item.patient_mobile },
                { text: item.admission_no },
                { text: commaFormat(item.total_paid_amount), alignment: 'right' },
                { text: commaFormat(item.refund_amount), alignment: 'right' }
            ];
        })
    }

    const getTableHead = () => {
        return [
            { text: 'DATE' },
            { text: 'MR Number' },
            { text: 'Voucher Number' },
            { text: 'Invoice Number' },
            { text: 'Receipt By' },
            { text: 'Mode of Payment' },
            { text: 'Receipt Type' },
            { text: 'Patient Name' },
            { text: 'Patient ID' },
            { text: 'Mobile Number' },
            { text: 'Admission Number' },
            { text: 'Received Amount' , alignment: 'right' },
            { text: 'Payment Amount' , alignment: 'right' }
        ]
    }

    const getTotals = (data) => {
        const totalPaidAmount = getTotal(data, 'total_paid_amount');
        const totalRefundAmount = getTotal(data, 'refund_amount');

        const totalAmounts = [
            { text: `Total (${data.length})`, colSpan: 7, alignment: 'right' },
            { text: '' },
            { text: '' },
            { text: '' },
            { text: '' },
            { text: '' },
            { text: '' },
            { text: '' },
            { text: '' },
            { text: '' },
            { text: '' },
            { text: commaFormat(totalPaidAmount), alignment: 'right' },
            { text: commaFormat(totalRefundAmount), alignment: 'right' },
        ];
        
        const netAmounts = [
            { text: 'Net Collection', colSpan: 7, alignment: 'right' },
            { text: '' },
            { text: '' },
            { text: '' },
            { text: '' },
            { text: '' },
            { text: '' },
            { text: '' },
            { text: '' },
            { text: '' },
            { text: '' },
            { text: '' },
            { text: commaFormat(totalPaidAmount - totalRefundAmount), alignment: 'right' },
        ];

        return [
            totalAmounts,
            netAmounts
        ]
    }

    const getTotal = (item, calculationField) => {
        return item.reduce((total, currentValue) => total + currentValue[calculationField], 0);
    }

    return{
        generatePdf,
        formatMoneyReceiptData
    }
}

export default moneyReceiptHelper;