import Network from '@/services/network'
import {ref} from 'vue'

export default function handleMoneyReceipt() {
	const network = new Network;
	const loading = ref(false)
	
	const fetchMoneyReceiptList = (query) => {
		return network.api('get', '/money-receipt' + query);
	}
	
	return {
		fetchMoneyReceiptList,
		loading
	}
}