<template>
  <div style="min-height: calc(100vh - 158px);" class="card">
    <div style="background: #DBE7F2" class="p-2">
      <TitleButton
          btnTitle="Add New"
          :showBtn="false"
          :showAddNew="true"
          :title="`${title} List`"
          @onClickAddNewButton="openModal"
      />
    </div>

    <div class="card p-2" style="min-height: 200px">
      <div class="table-responsive">
        <table class="table">
          <thead>
          <tr>
            <th>#</th>
            <th>Date</th>
            <th>Ref</th>
            <th>Short Desc</th>
            <th>Action</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(item, i) in activityLogs" :key="i">
            <td>{{ ++i }}</td>
            <td>{{ item.date }}</td>
            <td>{{ item.ref }}</td>
            <td>{{ item.short_description }}</td>
            <td>
              <button class="btn btn-primary btn-sm" @click="goToEdit(item)">
                <i class="fas fa-eye"></i>
              </button>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="mb-2"></div>
    <Loader v-if="loading"/>
  </div>

  <TabModal
      :title="title"
      :type="type"
      :isEdit="isEdit"
      ref="registerTypeModalRef"
      :formData="formData"
      @onSubmit="handleSubmit"
      @onClose="clearModal"
  />
</template>

<script setup>
const props = defineProps({
  type: {
    type: String,
    default: ''
  },
  title: {
    type: String,
    default: ''
  }
})

import TabModal from "@/views/page/company/contact/register/tabs/TabModal.vue";
import {inject, onMounted, ref, watch} from "vue";
import Loader from "@/components/atom/LoaderComponent.vue";
import TitleButton from "@/components/atom/TitleButton.vue";
import handleCompanyContact from "@/services/modules/companyContact";
import {useRoute, useRouter} from "vue-router";

const router = useRouter()
const route = useRoute()
const showError = inject('showError');
const showSuccess = inject('showSuccess');
const {fetchActivityLogs, storeActivityLogs, updateActivityLogs} = handleCompanyContact();
const registerTypeModalRef = ref(null);
const loading = ref(false)
const isEdit = ref(false)
const activityLogs = ref([])
const formData = ref({
  type: '',
  contact_id: '',
  date: new Date().toISOString().split('T')[0],
  ref: '',
  short_description: '',
  full_description: '',
  attachments: [],
  status: 'active'
})

function getQuery() {
  return `?company_id=${route.params.companyId}`;
}

const openModal = () => {
  isEdit.value = false
  registerTypeModalRef.value?.toggleModal()
}

const goToEdit = (item) => {
  isEdit.value = true
  formData.value = item
  registerTypeModalRef.value?.toggleModal(isEdit.value)
}


const handleSubmit = async () => {
  try {
    // Set loading states
    registerTypeModalRef.value.loading = true;

    // Set contact_id from query parameter
    formData.value.contact_id = route.query.contactId;

    // Create a FormData instance and copy selected fields
    const copyFormData = new FormData();
    const fieldsToCopy = [
      'type',
      'contact_id',
      'date',
      'ref',
      'short_description',
      'full_description',
      'status'
    ];

    for (const field of fieldsToCopy) {
      const value = formData.value[field];
      copyFormData.append(field, value || '');
    }

    // Handle attachments
    const attachments = formData.value.attachments;
    if (attachments && attachments.length) {
      handleAttachments(copyFormData, attachments);
    }

    // Call the appropriate API function
    const returnRes = isEdit.value
        ? await updateActivityLogs(formData.value.id, copyFormData, getQuery())
        : await storeActivityLogs(copyFormData, getQuery());

    // Handle the response
    handleResponse(returnRes);

  } catch (error) {
    console.error(error);
  } finally {
    // Reset loading states
    loading.value = registerTypeModalRef.value.loading = false;
  }
}

function handleAttachments(copyFormData, attachments) {
  const previousFileIds = attachments
      .filter(attachment => typeof attachment === 'object' && attachment.hasOwnProperty('id'))
      .map(attachment => attachment.id);

  attachments.forEach((attachment, index) => {
    if (typeof attachment !== 'object' || !attachment.hasOwnProperty('id')) {
      copyFormData.append(`attachments[${index}]`, attachment);
    }
  });

  if (previousFileIds.length) {
    copyFormData.append('previous_file_ids', JSON.stringify(previousFileIds));
  }
}

function handleResponse(res) {
  loading.value = false
  if (!res.status) {
    return showError(res.message)
  }
  showSuccess(res.message);

  if (!isEdit.value) {
    isEdit.value = false
    registerTypeModalRef.value.toggleModal()
    return activityLogs.value.unshift(res.data)
  }

  const index = activityLogs.value.findIndex(obj => obj.id === res.data.id);
  if (index !== -1) {
    activityLogs.value.splice(index, 1, res.data);
  }

  isEdit.value = false;
  registerTypeModalRef.value.toggleModal();

}

const clearModal = () => {
  formData.value = {
    type: props.type,
    contact_id: '',
    date: new Date().toISOString().split('T')[0],
    ref: '',
    short_description: '',
    full_description: '',
    status: 'active'
  }
}
const fetchActivityLogsData = () => {
  let query = getQuery();
  query += "&type=" + props.type;
  query += "&contact_id=" + route.query.contactId;
  fetchActivityLogs(query).then((res) => {
    if (!res.status) {
      activityLogs.value = [];
      return showError(res.message);
    }
    activityLogs.value = res.data;
  })
}

watch(() => route.query.contactId, (newValue) => {
  fetchActivityLogsData()
})
onMounted(() => {
  fetchActivityLogsData()
})

</script>

<style scoped>

</style>
