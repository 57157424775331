import pdfMake from 'pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import pdfHeaderFooter from '@/services/utils/pdf/pdfHeaderFooter';
import handlePurchase from "@/services/modules/purchase";
import handleHospital from "@/services/modules/hospital";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

pdfMake.fonts = {
    SulaimanLipi: {
        normal: 'https://fonts.cdnfonts.com/s/14639/solaimanlipi.woff',
        bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
        italics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
        bolditalics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf'
    }
}

const moneyReceipt = () => {
    const { getHeader, getFooter } = pdfHeaderFooter();
    const { currencyToWord, formatCurrency } = handlePurchase();
    const { calculateAge, capitalize } = handleHospital();

    const exportToPDF = (company, moneyReceipt, barcode, qrcode, barcodePatient, userName) => {

        var doc = {
            pageSize: 'A4',
            pageMargins: [ 25, 100, 25, 80 ],
            header: getHeader(company),
            footer: getFooter(userName, qrcode),
            content: getContent(moneyReceipt, barcode, barcodePatient),

            styles : {
                header: {
                    fontSize: 24,
                }
            },
            defaultStyle: {
                color: 'black',
                fontSize: 10,
                font: 'SulaimanLipi'
            },
            info: {
                title: 'Daily Sales Report'
            }
        }

        const pdfDocGenerator = pdfMake.createPdf(doc);
        pdfDocGenerator.open();
    }

    const getContent = (moneyReceipt, barcode, barcodePatient) => {
        return [
            {
                text: 'Money Receipt',
                style: 'header',
                alignment: 'center',
                margin: [0, 0, 20, 10],
                bold: true,
                color: 'black',
                decoration: 'underline'
            },

            {
                margin: [0, 20, 0, 0],
                alignment: 'justify',
                columns: [
                    {
                        stack: [
                            {
                                svg: barcodePatient
                            },

                            {
                                text: `${moneyReceipt.contact.serial_no ? 'ID: ' + moneyReceipt.contact.serial_no : ''}`
                            },

                            {
                                text: `${moneyReceipt.contact.name ? 'Name: ' + moneyReceipt.contact.name : ''}`
                            },

                            {
                                text: formatAgeAndGender(moneyReceipt.contact.birthday, moneyReceipt.contact.gender)
                            },

                            {
                                text: `${moneyReceipt.contact.mobile ? 'Phone No: ' + moneyReceipt.contact.mobile : ''}`
                            },

                            {
                                text: `${moneyReceipt.contact.full_address ? 'Address: ' + moneyReceipt.contact.full_address : ''}`
                            }
                        ]
                    },

                    {
                        alignment: 'right',
                        stack: [
                            {
                                svg: barcode
                            },

                            {
                                text: `${moneyReceipt.receipt_date ? 'Receipt Date: ' + moneyReceipt.receipt_date : ''}`
                            },

                            {
                                text: `${moneyReceipt.voucher_no ? 'Receipt Ref: ' + moneyReceipt.voucher_no : ''}`
                            },
                        ]
                    },
                ]
            },

            {
                style: 'tableExample',
                margin: [0, 10, 0, 0],
                table: {
                    widths: ['*', 'auto', 'auto', 'auto', 'auto', 'auto'],
                    body: getTableBody(moneyReceipt)
                },
                layout: {
                    fillColor: function (rowIndex, node, columnIndex) {
                        return (rowIndex === 0) ? '#f3f2f7' : null;
                    },
                    hLineWidth: function (i, node) {
                        return 0.5;
                    },
                    vLineWidth: function (i, node) {
                        return 0.5;
                    }
                }
            },

            {
                margin: [0, 15],
                text: [
                    {
                        text: 'In Words: ',
                        bold: true
                    },

                    {
                        text: `${moneyReceipt.total_paid_amount ? currencyToWord(moneyReceipt.total_paid_amount) : ''}`
                    },
                ]
            },

            {
                stack: [
                    {
                        text: `${moneyReceipt.description ? 'Note: ' : ''}`,
                        bold: true
                    },

                    {
                        text: `${moneyReceipt.description ? moneyReceipt.description : ''}`
                    },
                ]
            }
        ]
    }

    const getTableBody = (moneyReceipt) => {
        return [
            [
                { text: 'MR Date & Time'},
                { text: 'MR No' },
                { text: 'Mode of payment' },
                { text: 'MoP Ref.' },
                { text: 'Receipt by' },
                { text: 'Receipt Amount', alignment: 'right' }
            ],
            [
                { text: moneyReceipt.mr_receipt_date },
                { text: moneyReceipt.voucher_no },
                { text: moneyReceipt.account_head ? moneyReceipt.account_head.name : ''},
                { text: moneyReceipt.receipt_reference },
                { text: moneyReceipt.user.name },
                { text: moneyReceipt.total_paid_amount, alignment: 'right' }
            ]
        ]
    }

    const formatAgeAndGender = (age, gender) => {
        return `${age ? 'Age: ' + calculateAge(age) : ''}` + 
                `${gender ? ' | Sex: ' + capitalize(gender) : '' }`
    }

    return {
        exportToPDF
    }
}

export default moneyReceipt;